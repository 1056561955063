<template>
	<div class="wrapper" v-loading="loading">
		<div class="top-btn" ref="topBtn">
			<div>
				<el-select class="w110" v-model="search.search_gist" filterable placeholder="请选择" size="small">
					<el-option v-for="it in searchGist" :key="it.value" :label="it.label" :value="it.value"> </el-option>
				</el-select>
				<el-input class="w140" size="small" placeholder="请输入内容" v-model="search.desp" clearable> </el-input>
				<el-button size="small" type="primary" @click="Search()">搜索</el-button>
			</div>
			<div>
				<el-select class="w110" size="small" v-model="search.timeType">
					<el-option :key="1" label="提问时间" :value="1"></el-option>
					<el-option :key="2" label="回答时间" :value="2"></el-option>
				</el-select>
				<el-date-picker size="small" style="width: 230px" @change="Search()"
					v-model="search.time"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
			</div>
		</div>
		<el-table :data="list" :max-height="tableHeight" :header-cell-style="{background:'#f5f7fa'}" style="width: 100%">
			<el-table-column prop="create_time" label="提问时间">
				<template slot-scope="scope">{{ scope.row.create_time*1000 | formatDate }}</template>
			</el-table-column>
			<el-table-column prop="video_id" label="视频id"></el-table-column>
			<el-table-column prop="desp" label="视频描述" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="org_name" label="机构"></el-table-column>
            <el-table-column prop="city" label="机构城市"></el-table-column>
			<el-table-column prop="course_id_type" label="关联课程或优惠券">
				<template slot-scope="scope">
					{{ scope.row.course_id_type == 1 ? '课程：' : scope.row.course_id_type == 2 ? '优惠券：' : '--' }}
					{{ scope.row.course_name || '--' }}
				</template>
			</el-table-column>
			<el-table-column prop="city" label="机构城市"></el-table-column>
            <el-table-column prop="member_name" label="提问用户"></el-table-column>
			<el-table-column prop="title" label="提问内容" :show-overflow-tooltip="true"></el-table-column>
			<el-table-column prop="content" label="回答内容" :show-overflow-tooltip="true"></el-table-column>
			<el-table-column prop="answer_time" label="回答时间">
				<template slot-scope="scope">{{ scope.row.answer_time*1000 | formatDate }}</template>
			</el-table-column>
           <el-table-column prop="sharer_name" label="操作">
				<template slot-scope="scope">
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            操作<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="{type: 1,row: scope.row}">删除提问</el-dropdown-item>
                            <el-dropdown-item v-if="scope.row.answer_id" :command="{type: 2,row: scope.row}">删除问答</el-dropdown-item>
                            <!-- <el-dropdown-item :command="{fun: 'shield',row: scope.row.id}">对其他人屏蔽该次问答</el-dropdown-item> -->
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
			</el-table-column>
		</el-table>
		<el-pagination v-if="total > 0" style="padding-top: 15px; text-align: center;" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
		</el-pagination>
	</div>
</template>
<script>
	import { questionList, delQorA } from '@/api/courseOrder.js';
	export default {
		name: "QaManage",
		data() {
			return {
				loading: true,
				tableHeight: this.$constant.maxHeight,
				// 列表
				searchGist:  [{
					value: 'member_id',
					label: '用户id'
				}, {
					value: 'wx_nickname',
					label: '微信昵称'
				}, {
					value: 'video_id',
					label: '视频id'
				}, {
					value: 'org_name',
					label: '机构名称'
				}],
				// 列表
				search: {
					timeType: 1,
					search_gist: 'wx_nickname',
					desp: ''
				},
				// 列表
				total: 0,
				limit: 20,
				currentPage: 1,
				list: [],
				// 弹框
				dialogVisible: false,
				id: 0
			}
		},
		mounted() {
			this.getList()
			this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
			window.onresize = this.$common.debounce(()=>{
				this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
			}, 1000)
		},
		methods: {
			// 操作回调
			handleCommand(command) {
				let content = command.type == 1 ? '是否确定删除问题' : '是否确定删除答案'
				this.$confirm(content, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					delQorA({
						id: command.type == 1 ? command.row.id : command.row.answer_id,
						del_type: command.type,
					}).then((res) => {
						if(res.code == 200) {
							this.$message({
								message: '删除成功',
								type: 'success'
							});
							this.loading = true
							this.getList()
						}
					}).catch((err) => {
						console.log(err, 222)
					})
				}).catch(() => {});
			},
			// 获取列表
			getList() {
				questionList({
					limit: this.limit,
					page: this.currentPage,
					wx_nickname: this.search.search_gist == 'wx_nickname' ? this.search.desp : null,
					member_id: this.search.search_gist == 'member_id' ? this.search.desp : null,
					org_name: this.search.search_gist == 'org_name' ? this.search.desp : null,
					video_id: this.search.search_gist == 'video_id' ? this.search.desp : null,
					a_start_time: this.search.timeType == 2 ? this.$common.setSearchTime(this.search.time)[0] : null,
					a_end_time: this.search.timeType == 2 ? this.$common.setSearchTime(this.search.time)[1] : null,
					start_time: this.search.timeType == 1 ? this.$common.setSearchTime(this.search.time)[0] : null,
					end_time: this.search.timeType == 1 ? this.$common.setSearchTime(this.search.time)[1] : null,
				}).then((res) => {
					this.total = res.data.total;
					this.list = res.data.data;
					this.loading = false
				}).catch((err) => {
					this.loading = false
					console.log(err, 222)
				})
			},
			// 筛选
			Search(){
				this.loading = true
				this.currentPage = 1
				this.getList()
			},
			// 页码修改
			handleCurrentChange(val) {
				this.loading = true
				this.currentPage = val;
				this.getList();
			},
		}
	};
</script>
